<template>
  <el-dialog
    title="Disable Product"
    :visible="shouldShow"
    @close="closeModal"
    width="35%"
  >
    <el-row type="flex">
      <el-col :span="24">
        <p>
          Select a reason for disabling this product to complete this action:
        </p>
      </el-col>
    </el-row>
    <el-form :model="form" label-position="top" ref="form">
      <el-row>
        <el-col :span="18">
          <el-form-item prop="reason" :rules="validateField()">
            <el-checkbox-group v-model="form.reason" @change="setReason">
              <el-checkbox
                v-for="(reason, index) in reasons"
                :key="index"
                :label="reason"
              />
            </el-checkbox-group>
          </el-form-item>
          <el-form-item
            v-if="form.reason[0] === 'Other'"
            label="Enter your reason here"
            prop="other_reason"
            :rules="validateField()"
          >
            <el-input type="textarea" rows="2" v-model="form.other_reason" />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button type="danger" @click="disableProduct" :disabled="isDisabled"
        >Disable Product</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import product from "@/requests/products/product";
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    productId: String,
    vendorId: String,
  },

  data() {
    return {
      form: {
        reason: [],
        other_reason: "",
      },
      reasons: [
        "Product failed our quality check.",
        "Product does not relate to the vendor’s business category.",
        "Product is a counterfeit.",
        "Product is expired and no longer safe to use.",
        "Product has received too many negative user reviews.",
        "Product has legal or copyright issues.",
        "Product is out of stock.",
        "Product is a prohibited item that cannot be sold on the platform.",
        "Product is of low quality and does not meet our standards.",
        "Product is a duplicate of an existing listing.",
        "Product has a high rate of returns or refunds.",
        "Product is not licensed to be sold on the platform.",
        "Other",
      ],
    };
  },

  computed: {
    shouldShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
    isDisabled() {
      return !this.form.reason[0];
    },
  },

  methods: {
    closeModal() {
      this.shouldShow = false;
      this.form.reason = [];
    },

    setReason(reason) {
      this.form.reason = reason.length > 0 ? [reason.pop()] : [];
    },

    disableProduct() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return false;
        }
        const payload = {
          reason:
            this.form.reason[0] === "Other"
              ? this.form.other_reason
              : this.form.reason[0],
          product_id: this.productId,
          vendor_id: this.vendorId,
        };
        product
          .disable(this.vendorId, this.productId, payload)
          .then((response) => {
            if (response.data.status) {
              this.$message.success(response.data.message);
              this.$emit("success");
              this.closeModal();
            } else {
              this.$message.error(response.data.message);
            }
          })
          .catch((error) => {
            this.$message.error(error.response.data.message);
          });
      });
    },
  },
};
</script>

<style></style>
