<template>
  <div>
    <eden-table-actions :title="title" @search="search">
      <template slot="actions">
        <div
          v-if="multipleProducts.length >= 1"
          class="ml-10 is-flex is-align-center"
        >
          <div class="mr-4">
            {{ multipleProducts.length + " products selected" }}
          </div>
          <el-button
            @click="addCuration"
            type="primary"
            :disabled="loading || !pageData?.length"
          >
            Add to Curation
          </el-button>
        </div>
      </template>
    </eden-table-actions>

    <eden-loader v-if="loading" />

    <template v-else>
      <el-table
        table-layout="auto"
        :data="pageData"
        :key="sort.property"
        ref="multipleTableRef"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" />

        <el-table-column min-width="200">
          <template #header>
            <eden-table-column-header
              :label="'Name'"
              :property="'name'"
              :sort-property="sort.property"
              @sort="sortPageData($event, 'name')"
            />
          </template>
          <template v-slot="scope">
            <div class="is-flex is-align-center">
              <div class="eden-meal--image">
                <img
                  v-if="scope.row.image_url || scope.row.image_url === ''"
                  :src="getThumbnailFromCloudinary(scope.row.image_url)"
                  class="meal--image"
                  alt="Image"
                  width="36px"
                  height="36px"
                />
              </div>
              {{ formatMealName(scope.row.name) }}
            </div>
          </template>
        </el-table-column>

        <el-table-column min-width="200">
          <template #header>
            <eden-table-column-header
              :label="'Price'"
              :property="'price'"
              :sort-property="sort.property"
              @sort="sortPageData($event, 'price')"
            />
          </template>
          <template v-slot="scope">
            <span>{{ formatPrice(scope.row.price) }}</span>
          </template>
        </el-table-column>

        <el-table-column min-width="200">
          <template #header>
            <eden-table-column-header
              :label="'Status'"
              :property="'status'"
              :sort-property="sort.property"
              @sort="sortPageData($event, 'status')"
            />
          </template>
          <template v-slot="scope">
            <el-tag :type="getTagType(scope.row.is_active, scope.row.is_draft)">
              <span>{{
                !scope.row.is_active
                  ? "Disabled"
                  : scope.row.is_draft
                  ? "Not Published"
                  : "Published"
              }}</span>
            </el-tag>
          </template>
        </el-table-column>

        <el-table-column min-width="200">
          <template #header>
            <eden-table-column-header
              :label="'Name of Vendor'"
              :property="'vendor'"
              :sort-property="sort.property"
              @sort="sortPageData($event, 'vendor')"
            />
          </template>
          <template v-slot="scope">
            <span>{{ scope.row.vendor.business_name }}</span>
          </template>
        </el-table-column>

        <el-table-column min-width="200">
          <template #header>
            <eden-table-column-header
              :label="'Ratings'"
              :property="'ratings'"
              :sort-property="sort.property"
              @sort="sortPageData($event, 'ratings')"
            />
          </template>
          <template v-slot="scope">
            <span>{{ scope.row.average_rating }}</span>
          </template>
        </el-table-column>

        <el-table-column min-width="200">
          <template #header>
            <eden-table-column-header
              :label="'Quantity Sold'"
              :property="'quantity'"
              :sort-property="sort.property"
              @sort="sortPageData($event, 'quantity')"
            />
          </template>
          <template v-slot="scope">
            <span>{{ scope.row.quantity }}</span>
          </template>
        </el-table-column>

        <el-table-column min-width="60">
          <template slot-scope="scope">
            <el-dropdown @command="command" class="more">
              <span class="el-dropdown-link">
                <i class="eden-icon-more"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-if="scope.row.is_active"
                  :command="{
                    action: 'disable',
                    id: scope.row.uuid,
                    vendorId: scope.row.vendor.uuid,
                    index: scope.$index,
                  }"
                >
                  Disable Product
                </el-dropdown-item>
                <el-dropdown-item
                  v-else
                  :command="{
                    action: 'enable',
                    id: scope.row.uuid,
                    vendorId: scope.row.vendor.uuid,
                    index: scope.$index,
                  }"
                >
                  Enable Product
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>

      <eden-pagination
        v-if="showPagination"
        :from="from"
        :to="to"
        :total="total"
        :current-page.sync="page"
        :page-size="pageSize"
      />
    </template>

    <product-to-curation
      v-if="showAddCuration"
      :show.sync="showAddCuration"
      @shower="activateCreateCuration"
      :products="multipleProducts"
    />

    <create-curations
      v-if="showCreateCuration"
      :show.sync="showCreateCuration"
    />
    <disable-product
      :show.sync="showDisableProduct"
      :product-id="productId"
      :vendor-id="vendorId"
      @success="fetchProducts"
    />
  </div>
</template>

<script>
import CreateCurations from "./CreateCurations.vue";
import ProductToCuration from "./ProductToCuration.vue";
import product from "../../requests/products/product";
import DisableProduct from "./DisableProduct.vue";

export default {
  components: {
    ProductToCuration,
    CreateCurations,
    DisableProduct,
  },

  props: {
    tags: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      showCreateCuration: false,
      loading: false,
      page: 1,
      multipleProducts: [],
      pageData: [],
      showAddCuration: false,
      showDisableProduct: false,
      showPagination: true,
      sort: {
        property: "name",
        direction: "asc",
      },
      filterParams: {
        clear: false,
        status: false,
        params: {},
        paramsLabel: {},
      },
      showExportCustomers: false,
      pageDataTime: "",
      exportData: [],
      productId: "",
      vendorId: null,
    };
  },
  watch: {
    tags: "getProducts",
    page: "getProducts",
  },
  computed: {
    title() {
      return this.pageData?.length + " Products";
    },
  },

  created() {
    this.getProducts();
  },

  methods: {
    getTagType(isActive, isDraft) {
      return !isActive ? "info" : isDraft ? "info" : "success";
    },
    command(command) {
      this.productId = command.id;
      this.vendorId = command.vendorId;
      switch (command.action) {
        case "disable":
          this.showDisableProduct = true;
          break;
        case "enable":
          this.enableProduct();
          break;
      }
    },
    sortPageData(direction, property) {
      this.sort.property = property;
      this.sort.direction = direction;
      this.pageData = this.sortList(this.pageData, property, direction);
    },

    enableProduct() {
      product
        .enable(this.vendorId, this.productId)
        .then((response) => {
          if (response.data.status) {
            this.$message.success(response.data.message);
            this.fetchProducts();
          }
        })
        .catch((error) => {
          this.$message.error(error.response.data.message);
        });
    },
    handleSelectionChange(val) {
      this.multipleProducts = val;
    },
    addCuration() {
      this.showAddCuration = true;
    },
    activateCreateCuration() {
      this.showCreateCuration = true;
    },

    search(query) {
      this.searchQuery = query;
      this.page = 1; // Reset page to 1 when a new search is made
      this.getProducts();
    },
    fetchProducts(params) {
      product
        .list(params)
        .then(this.handleOrderResponse)
        .catch(this.handleError);
    },
    handleOrderResponse({ data }) {
      const { status, data: productData } = data;
      if (status) {
        this.pageData = productData.data;
        this.from = productData.from;
        this.to = productData.to;
        this.total = productData.total;
        this.pageSize = productData.per_page;
        this.loading = false;
      }
    },
    getProducts() {
      this.loading = true;

      let params = `use_lite=0&page=${this.page}`;

      if (this.tags.id !== "") {
        params = `filters[business_type_ids]=[${this.tags.id}]&use_lite=0&page=${this.page}`;
      }

      if (this.searchQuery) {
        params += `&search=${this.searchQuery}`;
      }
      this.fetchProducts(params);
    },

    handleError(error) {
      this.loading = false;
      const errorMessage = error.response?.data;

      if (errorMessage.errors) {
        const errorKeys = Object.keys(errorMessage.errors);
        this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
      } else {
        this.$message.error(errorMessage.message);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.meal--image {
  border-radius: 8px;
  margin-right: 10px;
}
</style>
