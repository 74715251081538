<template>
  <div class="">
    <eden-page-header :title="'Products'"> </eden-page-header>

    <el-tabs v-model="tab" @tab-click="updateRoute">
      <el-tab-pane
        v-for="eachTab in tabs"
        :label="eachTab.label"
        :name="eachTab.name"
        :key="eachTab.name"
      >
        <component :is="'ProductsTable'" :tags="eachTab" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import ProductsTable from "@/components/Products/ProductsTable";
import * as actions from "@/store/action-types";

export default {
  name: "Products",

  components: {
    ProductsTable,
  },

  data() {
    return {
      tab: "all",
    };
  },

  computed: {
    tabs() {
      let businessTypes = this.$store.getters.business_types.map((type) => ({
        name: type.name.toLowerCase(),
        label: type.name,
        id: type.id,
      }));
      // Add an 'all' tab to the beginning of the array
      businessTypes.unshift({ name: "all", label: "All", id: "" });

      return businessTypes;
    },
    businessTypes() {
      return this.$store.getters.business_types;
    },
  },

  created() {
    let { tab } = this.$route.query;
    this.tab = tab ? tab : "all";
    if (!tab) {
      this.$router.push({
        name: "products.index",
        query: { tab: "all" },
      });
    }
    this.getBusinessType();
  },

  methods: {
    getBusinessType() {
      this.loading = true;
      this.$store.dispatch(actions.GET_BUSINESS_TYPE).then(() => {
        this.businessType = this.businessTypes;
        this.loading = false;
      });
    },
    updateRoute(tab) {
      const currentTab = this.$route.query.tab;

      if (currentTab === this.tab) {
        return false;
      }
      this.$router.push({
        name: "products.index",
        query: { tab: tab.name },
      });
    },
  },
};
</script>
