<template>
  <el-dialog
    :title="'Add to Curation'"
    :visible.sync="shouldShow"
    @close="closeEvent"
    width="30%"
  >
    <div>
      <el-input
        class=""
        v-model="searchQuery"
        type="text"
        :prefix-icon="'el-icon-search'"
        placeholder="Search"
      ></el-input>

      <ul class="curation-list">
        <li
          v-for="item in computedCurations"
          :key="item.name"
          class="text-primart font-md"
          @click="selectCuration(item)"
        >
          {{ item.name }}

          <i v-if="item.isSelected" class="eden-icon-check"></i>
        </li>
      </ul>
    </div>

    <span
      slot="footer"
      class="dialog-footer is-flex is-justify-between is-align-center"
    >
      <div class="text-primary font-sm create-curation--link" @click="activateCreateCuration">
        Create a curation +
      </div>
      <el-button
        type="primary"
        :disabled="canAddCuration"
        :loading="loading"
        @click="addToCuration"
        >Add to curation</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import curation from '../../requests/curations/curation';
export default {
  name: "ProductToCuration",

  components: {},

  emits: ["update:show", "shower"],

  data() {
    return {
      loading: false,
      searchQuery: "",
      Curations: [],
      selectedCuration: null,
    };
  },

  props: {
    show: Boolean,
    products: {
      type: Object,
      default: () => [] 
    }
  },

  computed: {
    shouldShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
    computedCurations: {
      get() {
        return this.Curations.map((item) => {
          if (item.id === this.selectedCuration?.id) {
            return { ...item, isSelected: true };
          }
          return { ...item, isSelected: false };
        });
      },
    },
    canAddCuration() {
      return !Object.keys(this.selectedCuration ?? []).length;
    },
  },

  created() {
    this.getCurations();
  },


  methods: {
    closeEvent() {
      this.shouldShow = false;
    },

    getCurations() {
      this.loading = true;
      curation
        .index()
        .then((response) => {
          this.Curations = response.data.data.data;
          console.log(this.Curations, "Whatrs")
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },

    addToCuration() {
      this.loading = true;
      console.log(this.selectedCuration.uuid)
      let payload = {
        product_ids: this.products.map(item => item.uuid)
      }
      curation
        .attach(this.selectedCuration.uuid, payload)
        .then((response) => {
          this.$message.success(response.data.message);
          this.closeEvent()
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },

    activateCreateCuration() {
      this.$emit('shower')
    },

    selectCuration(item) {
      if (this.selectedCuration?.id === item.id) {
        this.selectedCuration = null;
        return;
      }
      this.selectedCuration = item;
    },
  },
};
</script>

<style lang="scss" scoped>
.curation-list {
  list-style: none;
  margin: 0;
  padding: 0;
  & > li {
    padding: 1rem 0;
    border-bottom: 1px solid #f0f4f2;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.create-curation--link{
  cursor: pointer;
}
</style>
